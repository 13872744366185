<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="EMAIL_AD_ONS"
      tooltip-title="EMAIL_AD_ONS"
      :display-breadcrumb="true"
    ></TitleWrapper>

    <div v-if="isLoading" class="mt-28">
      <Loader :content="true" />
    </div>
    <div
      v-else
      class="bg-white min-h-screen md:py-7.5 py-3 md:px-7.5 px-3 mt-5 border border-primary-grey rounded-lg font-roboto font-medium text-text-color text-xs sm:text-sm md:text-base"
    >
      <div>
        <!-- Provider Type and api key -->
        <div class="flex flex-col gap-3 md:gap-5">
          <div class="flex items-center">
            <div class="min-w-1 sm:w-1/4 md:w-2/4">
              <span>Provider Type:</span>
            </div>
            <div class="text-primary-grey-light capitalize">
              {{
                adapterDetails.adapter_provider &&
                transformTextToCapitalize(adapterDetails.adapter_provider)
              }}
            </div>
          </div>
          <div class="flex items-center">
            <div class="min-w-1 sm:w-1/4 md:w-2/4">
              <span>Email</span>
            </div>
            <div class="text-primary-grey-light">
              {{ adapterDetails.from_email }}
            </div>
          </div>
          <div class="flex items-center">
            <div class="min-w-1 sm:w-1/4 md:w-2/4">
              <span>Email Title</span>
            </div>
            <div class="text-primary-grey-light">
              {{ adapterDetails.from_title }}
            </div>
          </div>
        </div>

        <div class="flex gap-5 mt-5 md:mt-7.5 flex-col border-t border-primary-grey pt-5 md:pt-7.5">
          <div class="min-w-1 sm:w-1/4 md:w-2/4">Secret Keys</div>
          <div
            v-for="(value, key, index) in adapterDetails.adapter_config"
            :key="index"
            class="flex items-center text-primary-grey-light"
          >
            <div class="min-w-1 sm:w-1/4 md:w-2/4">
              <span>{{ transformTextToCapitalize(key) }}</span>
            </div>
            <div class="p-1 rounded">{{ value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@components/BaseComponent/Loader.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions } from 'vuex'
import { transformTextToCapitalize } from '@utils/generalUtil.js'
export default {
  components: {
    Loader,
    TitleWrapper,
  },
  data() {
    return {
      adapterDetails: {},
      isLoading: false,
    }
  },
  computed: {
    currentAdapterId() {
      return this.$route?.params?.id || ''
    },
  },
  mounted() {
    this.getProviderDetails()
  },
  methods: {
    transformTextToCapitalize,
    getProviderDetails() {
      this.isLoading = true
      let id = this.currentAdapterId
      this.getEmailProviderDetails(id).then((res) => {
        this.adapterDetails = res?.data
        this.isLoading = false
      })
    },
    ...mapActions('addons', ['getEmailProviderDetails']),
  },
}
</script>
